import Glider from 'glider-js';

$(document).ready(() => {
    let carousel = document.querySelector('.js-slider-carousel')
    if(carousel){
        const slider = new Glider(carousel, {
            slidesToShow: 1,
            slidesToScroll: 1,
            scrollLock: true,
            dots: '.js-slider-carousel-dots',
            arrows: {
                prev: '.js-slider-carousel-prev',
                next: '.js-slider-carousel-next',
            },
        });
        function sliderAuto(slider, miliseconds) {
            const slidesCount = slider.track.childElementCount;
            let slideTimeout = null;
            let nextIndex = 1;
            let duration = miliseconds
            let videos = []

            function slide (){
                let current = nextIndex - 1
                if(slider.slides[current].querySelector('video')){
                    let video = slider.slides[current].querySelector('video')
                    video.pause();
                    video.currentTime = 0;
                    video.play();
                    if(videos[current]){
                        slideTimeout = setTimeout(
                            function () {
                                if (nextIndex >= slidesCount ) {
                                    nextIndex = 0;
                                }
                                slider.scrollItem(nextIndex++);
                            },
                            videos[current] * 1000
                        );
                    }else{
                        video.onloadedmetadata = () => {
                            console.log(video.duration)
                            videos[current] = video.duration
                            slideTimeout = setTimeout(
                                function () {
                                    if (nextIndex >= slidesCount ) {
                                        nextIndex = 0;
                                    }
                                    slider.scrollItem(nextIndex++);
                                },
                                video.duration * 1000
                            );
                        }
                    }
                }else{
                    duration = miliseconds
                    slideTimeout = setTimeout(
                        function () {
                            if (nextIndex >= slidesCount ) {
                                nextIndex = 0;
                            }
                            slider.scrollItem(nextIndex++);
                        },
                        duration
                    );
                }

                /*slideTimeout = setTimeout(
                    function () {
                        if (nextIndex >= slidesCount ) {
                            nextIndex = 0;
                        }
                        slider.scrollItem(nextIndex++);
                    },
                    duration
                );*/
            }

            slider.ele.addEventListener('glider-animated', function() {
                window.clearInterval(slideTimeout);
                slide();
            });

            slide();
        }

        sliderAuto(slider, 5000)
    }

});
